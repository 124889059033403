<template>
    <li class="nav-item">
        <h5 class="branch-name">{{ branchName }}</h5>
    </li>
  </template>
  <script>
  import {
    BFormInput, BLink, BImg, BAvatar,
  } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  const branchName = ''
  export default {
    components: {
      BFormInput,
      BLink,
      BImg,
      BAvatar,
      VuePerfectScrollbar,
    },
    mounted() {
            const userData = this.$cookies.get('userData')
            const userBranch = userData.branch
        this.branchName = userBranch !== null ? `Branch : ${userBranch.branch_name}` : ''
    },
  data() {
    return {
      branchName,
    }
  },
  }
  </script>
  <style>
  .branch-name{
    padding-top:10px;
    padding-right: 20px;
  }
</style>